import { MutationTree, Mutation } from 'vuex';
import { IState } from './state';
import { ActionPayload, ISetActivePayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const setActiveTabMutation: MutationHandler<ISetActivePayload> = (state, { payload }) => {
	state.tabs.forEach(tab => {

		let activeTypeButton
		if(tab.active && payload.id === tab.id) {
			activeTypeButton = false
		} else {
			activeTypeButton = payload.id === tab.id ? true : false
		}

		Object.assign(tab, {
			active: payload.type === 'buttons' ? activeTypeButton : payload.id === tab.id
		})
	})
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	setActiveTab: setActiveTabMutation
}

export default mutations
