
import Vue from 'vue'
import { ITab } from '../store/state'

interface TabsComputed {
    tabs: ITab[]
}

export default Vue.extend<{}, {}, TabsComputed, {}>({
	name: 'TabContent',
    mounted() {
        // we need to eval scripts in content, v-html does not. Needed when vue apps is tab content
        let scriptStrings: string[] = [];
        this.$store.getters.tabs.forEach((t: ITab) => {
            let tempDiv = document.createElement('div');
            tempDiv.innerHTML = t.content;
            let scriptElements = tempDiv.querySelectorAll('script');
            scriptElements.forEach(se => {
                if(se.textContent && !scriptStrings.includes(se.textContent)) {
                    scriptStrings.push(se.textContent);
                }
            })
        })
        scriptStrings.forEach(ss => {
            try {
                eval(ss);
            } catch(e) {
                console.log(e);
            }
        })
        
    },
	computed: {
        tabs() {
            return this.$store.getters.tabs
        }
    }
})
