import { IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';

type PayloadType = 'initApp' | 'setState' | 'setActiveTab'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

export interface ISetActivePayload {
	id: string
	type: string
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

export const setActiveTab: ActionCreator<string> = (id) => ({
	type: 'setActiveTab',
	payload: id
})

// Action handlers

const setActiveTabAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	const type = state.type;
	
	commit({
		type: 'setActiveTab',
		payload : {
			id: payload,
			type: type
		}
	})
}

const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	setActiveTab: setActiveTabAction
}

export default actions;
