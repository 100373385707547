
import Vue from 'vue'
import Tabs from './components/Tabs.vue';
import TabContent from './components/TabContent.vue'

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	components: {
		Tabs,
		TabContent
	},
	computed: {
		type() {
            return this.$store.getters.type
        }
	}
})
