
import Vue from 'vue'

const removeURLParameter = (url: string, parameterName: string) => {
    // Create a URL object to parse the URL
    const urlObj = new URL(url);

    // Remove the parameter
    urlObj.searchParams.delete(parameterName);

    // Reconstruct the URL with the updated search parameters
    return urlObj.toString();
}

interface TabsMethods {
    setActiveTab(tab: ITab): void;
}
interface TabsComputed {
    tabs: ITab[]
}

import { setActiveTab } from '../store/actions'
import { ITab } from '@/store/state';
export default Vue.extend<{}, TabsMethods, TabsComputed, {}>({
	name: 'Tabs',
	computed: {
        tabs() {
            return this.$store.getters.tabs
        }
    },
    methods: {
        setActiveTab(tab) {
            if(!tab.active) {
                const currentURL = new URL(window.location.href);
                const params = new URLSearchParams(currentURL.search);
                params.set('tab', tab.id);

                const newURL = currentURL.origin + currentURL.pathname + '?' + params.toString();
                history.pushState(null, '', newURL);
            } else {
                history.pushState(null, '', removeURLParameter(window.location.href, 'tab'));
            }
            console.log(tab.active)
            this.$store.dispatch(setActiveTab(tab.id))
            console.log(tab.id);
        }
    }
})
