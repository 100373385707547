export interface IState {
	tabs: ITab[],
	type: string
}

export interface ITab {
	name: string,
	content: string,
	active: boolean,
	id: string
}

const deafultState: IState = {
	tabs: [],
	type: '',
}

export default deafultState;
