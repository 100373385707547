import { Getter } from "vuex";
import { IState } from './state';


const tabs: Getter<IState, IState> = (state) => state.tabs;
const type: Getter<IState, IState> = (state) => state.type;

export default {
	tabs,
	type
}
